export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return undefined; // Return undefined when the cookie is not found
}

export const HOST_URL = "https://www.server.foodiehubofficial.art"
